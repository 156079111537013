<template>
  <div class="hp-about section--pad">
    <div class="container">
      <div class="hp-about--slider">
        <Swiper
            :modules="[SwiperAutoplay, SwiperEffectFade]"
            :fade-effect="{ crossFade: true }"
            :effect="'fade'"
            :autoplay="true"
            :loop="true"
            :speed="1000"
        >
          <SwiperSlide v-for="(item, index) in hpAbout.slider" :key="index">
            <div class="slider-item">
              <h2 class="text-center title-h2">{{ item?.title }}</h2>
              <div class="subtitle-semibold text-center mb-4">{{ item?.subtitle }}</div>
              <div class="text container--sm" style="max-width: 700px" v-html="item?.text"></div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div class="more-links d-flex justify-content-center py-2">
          <nuxt-link class="link mx-3" :to="parseLink(hpAbout.link.url)">{{ hpAbout.link.title }}</nuxt-link>
          <a class="link mx-3" data-fancybox :href="hpAbout.videoLink"
          >צפייה בסרטון
            <nuxt-img
                class="d-inline-block mx-1"
                width="20"
                loading="lazy"
                src="/images/play-button.png"
                alt="אייקון הפעל וידאו"
            />
          </a>
        </div>
      </div>
      <IconsSlider :icons="options?.globalIcons"></IconsSlider>
    </div>
  </div>
</template>
<script setup lang="ts">
const {currentPage, options} = useGlobalStore();
const {hpAbout} = currentPage?.template?.homepage;
</script>
<style lang="scss">
.hp-about--slider {
  margin-bottom: 60px;
}
</style>
